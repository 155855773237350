// information for Navbar component
import * as React from "react";
import { Link } from "@reach/router";
import { css } from "@emotion/core";
import "./Navbar.css";
import colors from "../../common_styles/colors";

export interface NavProps {
  navSection?: string;
  navFramework?: string;
  visible?: boolean;
}

const Navbar = (props: NavProps) => (
  <header
    className="navbar"
    css={css`
      background-color: ${colors.pink};
      color: white;
      padding: 15px;

      &:hover {
        color: yellow;
      }
    `}
  >
    <Link className="home navbar-item navlink" to="/">
      Home
    </Link>
    <span
      css={css`
        font-size: 60px;

        &:hover {
          text-decoration: underline;
        }
      `}
      role="img"
      aria-label="logo"
    >
      ♺
    </span>
    <Link className="hello navbar-item navlink" to="/hello">
      Hello
    </Link>
    <span
      css={css`
        font-size: 60px;
      `}
      role="img"
      aria-label="waving hands"
    >
      👋🏼
    </span>
    <Link className="about navbar-item navlink" to="/about">
      About
    </Link>
    <span
      css={css`
        font-size: 60px;
      `}
      role="img"
      aria-label="question mark"
    >
      ❔
    </span>
    <Link className="contact navbar-item navlink" to="/contact">
      Contact
    </Link>
    <span
      css={css`
        font-size: 60px;
      `}
      role="img"
      aria-label="talking head"
    >
      🗣
    </span>
    <Link className="projects navbar-item navlink" to="/projects">
      Projects
    </Link>
    <span
      css={css`
        font-size: 60px;
      `}
      role="img"
      aria-label="crossing hammer and wrench"
    >
      🛠
    </span>
  </header>
);

// const Navbar = (props: NavProps) => (
//   <nav className={`navbar${props.visible ? "" : " is-invisibile"}`}>
//     <img
//       className="navbar-item"
//       src="/assets/icons/plusOne.jpg"
//       alt="give +1"
//     />
//     <img
//       className="navbar-item"
//       src="/assets/icons/comment.jpg"
//       alt="comment"
//     />
//     <img className="navbar-item" src="/assets/icons/add.jpg" alt="add" />
//     <img className="navbar-item" src="/assets/icons/arrow.jpg" alt="share" />
//     <div className="Navbar">
//       <h1>
//         Hello from {props.navSection} and {props.navFramework}! You did it, Ace!
//       </h1>
//       <h2> More coming soon...Nav</h2>
//     </div>
//   </nav>
// );

// export const Navbar = (props: NavProps) => (
//   <div className="Navbar">
//     <h1>
//       Hello from {props.navSection} and {props.navFramework}! You did it, Ace!
//     </h1>
//     <h2> More coming soon...Nav</h2>
//   </div>
// );

export default Navbar;
