import * as React from "react";
import * as ReactDOM from "react-dom";
import { Router } from "@reach/router";

import "./App.css";
import Hello from "../Hello";
import Navbar from "../Navbar";

// export interface AppProps {
//   appSection: string;
//   appFramework: string;
// }
export const App = () => {
  return (
    <div className="Router">
      <main>
        <Navbar />
        <Hello compiler="TypeScript" framework="React" />
      </main>
    </div>
  );
};

export default App;
