// information for Hello component
import * as React from 'react';
import './Hello.css';

export interface HelloProps {
  compiler: string;
  framework: string;
}

export const Hello = (props: HelloProps) => (
  <div className="Hello">
    <h1>
      Hello from {props.compiler} and {props.framework}! You did it, Ace!
    </h1>
    <h2> More coming soon...Traveled from Hello to App to index</h2>
  </div>
);

export default Hello;
